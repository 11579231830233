import ProjectEmbedCard from "../projects/projectCards/templates/cardEmbed"
import React from "react"
import '../projects/projectCards/templates/card.css'


class Wedding extends React.Component {

    render() {
    return (
      window.location.replace('https://www.theknot.com/us/jordan-jarvis-and-rebecca-kiser/	'))
}
}
export default Wedding
