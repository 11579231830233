/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:e606835f-1ddf-4516-9db6-7fe4a3bb8493",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_YLn4Udg8G",
    "aws_user_pools_web_client_id": "128p3je1i225e79di2dq71d915",
    "oauth": {},
    "aws_user_files_s3_bucket": "projects215100-staging",
    "aws_user_files_s3_bucket_region": "us-east-2",
    "aws_cloud_logic_custom": [
        {
            "name": "backendportfolio",
            "endpoint": "https://dpwhae1681.execute-api.us-east-2.amazonaws.com/staging",
            "region": "us-east-2"
        }
    ]
};


export default awsmobile;
